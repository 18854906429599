<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="通知模版" prop="templateId">
          <el-select v-model="form.templateId" :disabled="onlyLook" placeholder="请选择通知模版">
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.templateName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通知标题" prop="noticeTitle">
          <el-input placeholder="请输入通知标题" v-model="form.noticeTitle" :disabled="onlyLook" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="通知人群" prop="noticeCrowd">
              <el-select v-model="form.noticeCrowd" :disabled="onlyLook" multiple placeholder="请选择通知人群">
                <el-option label="客户" value="1"></el-option>
                <el-option label="用户" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-select v-model="form.status" :disabled="onlyLook" placeholder="请选择消息状态">
                <el-option label="已推送" value="1"></el-option>
                <el-option label="未推送" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-form-item label="通知机构" prop="noticeCompany">
          <el-select v-model="form.noticeCompany" :disabled="onlyLook" multiple placeholder="请选择通知机构">
            <el-option v-for="(item, index) in companyList" :key="index" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效期" prop="value1">
          <el-date-picker
            @change="changeDate"
            :disabled="onlyLook"
            v-model="form.value1"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api'
export default {
  data () {
    return {
      form: {
        templateId: '',
        noticeTitle: '',
        noticeCrowd: '',
        status: '',
        noticeCompany: '',
        address: '',
        model: '',
        beginDate: '',
        endDate: '',
        value1: '',
        createBy: '1'
      },
      rules: {
        templateId: [
          {required: true, message: '请选择通知模版', trigger: 'change'}
        ],
        noticeTitle: [
          {required: true, message: '请输入通知标题', trigger: 'blur'}
        ],
        noticeCrowd: [
          {required: false, message: '请选择通知人群', trigger: 'change'}
        ],
        status: [
          {required: true, message: '请选择通知状态', trigger: 'change'}
        ],
        noticeCompany: [
          {required: false, message: '请选择通知机构', trigger: 'change'}
        ],
        value1: [
          {required: false, message: '请选择通知有效期', trigger: 'change'}
        ],
      },
      dialogVisible: true,
      modelList: [],
      companyList: [],
      title: '新建管理模版'
    }
  },
  props: ['info', 'onlyLook'],
  mounted(){
    if (this.info != '') {
      this.title = '修改管理模版'
      postRequestAnthor('/noticemanage/querynoticemanageinfo', {id: this.info.id}).then(res=>{
        console.log(res, '单条数据查询结果')
        res.templateId= Number(res.templateId)
        res.value1 = [res.beginDate, res.endDate]
        this.form = res
        this.form.noticeCompany = res.noticeCompany ? res.noticeCompany.split(',').map(item => Number(item)) : ''
        this.form.noticeCrowd = res.noticeCrowd ? res.noticeCrowd.split(',') : ''
        this.form.templateId = Number(res.templateId)
      })
    }
    postRequestAnthor('/noticetemplate/querynoticetemplatebynormal',{}).then(res=>{
      console.log(res, 'res')
      this.modelList = res
    })
    postRequestAnthor('/company/queryCompany',{}).then(res=>{
      console.log(res, 'res')
      this.companyList = res
    })
  },
  methods: {
    handleClose () {
      this.$emit('closeAddnotice')
    },
    sureCommit(form){
      if (this.onlyLook) {
        this.handleClose()
      } else {
        this.$refs[form].validate((valid) => {
          if (valid) {
            if (this.info != '') {
              console.log('修改', this.form)
              this.form.noticeCrowd = this.form.noticeCrowd ? this.form.noticeCrowd.join(',') : ''
              this.form.noticeCompany = this.form.noticeCompany ? this.form.noticeCompany.join(',') : ''
              postRequestAnthor('/noticemanage/update',this.form).then(res=>{
                console.log(res)
                this.$message({
                  message: '修改成功',
                  type: 'success'
                })
                // this.handleClose()
                this.$emit('closedialog')
              })
            } else {
              console.log('添加', this.form)
              this.form.noticeCrowd = this.form.noticeCrowd ? this.form.noticeCrowd.join(',') : ''
              this.form.noticeCompany = this.form.noticeCompany ? this.form.noticeCompany.join(',') : ''
              postRequestAnthor('/noticemanage/addsave',this.form).then(res=>{
                console.log(res)
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
                this.handleClose()
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    changeDate(val){
      this.$forceUpdate()
      console.log(val)
      this.form.beginDate = val[0],
      this.form.endDate = val[1]
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
}
.el-date-editor {
  width: 100%;
}
.line {
  text-align: center;
}
.el-card {
  margin-top: -25px;
}
</style>